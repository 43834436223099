/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import axios from "axios";
import yaml from "js-yaml";

class YamlLoader {
    
    load = async(path) => {
        try {
            //load messages from yml
            let resp = await axios.get(path);
            if(resp.data){
              //parse YML
              let parsedYaml = yaml.safeLoad(resp.data);
              return parsedYaml;
            }
        
          } catch{
          }
          return null;
    }

}
  
export default YamlLoader;
  