/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import React from "react";
import PostsProvider from "./PostsProvider";
import {Button} from "react-bootstrap";
import Utils from "./Utils";

class PostsSidebar extends React.Component {

    provider = null;
    lang = "en";
    MAX_LINKS = 30;

    constructor(props){
        super(props);
        if(this.props.lang) this.lang = this.props.lang;
        if(this.lang){
            this.provider = new PostsProvider(this.lang);
        }

        this.state = {
            posts : null
        }
    }


    componentDidMount = async() => {

        if(this.provider){
            await this.provider.loadPosts();
            if(this.provider.posts) this.setState({posts:this.provider.posts});
        }
    }

    render(){
        let posts = this.state.posts;
        if(!window.mciTranslations || !posts) return <></>
        let msg = window.mciTranslations;
        posts = posts.slice(0,this.MAX_LINKS);

        const markup = posts.map((postsItem, index) => {
            if(!postsItem) return <></>;
            let title = postsItem.title;
            if(!title) return <></>;
            let postsId = Utils.getId(title);
            return <span key={`posts-${index}`}>
                <p>
                    <a href={`/${postsId}`}>{title}</a>
                </p>
            </span>
            }
        );

        return <>
                <h2>{msg.LatestPosts[this.lang]}</h2>
                {markup}
                <Button variant="outline-primary" href={`/`}>{msg.ReadMore[this.lang]}</Button>
            </>
    }

}
  
export default PostsSidebar;
  