let getId = (title) => {
    let id = title.replace(/[/?]/gi,"");
    id = id.replace(/[^a-z0-9]/gi, "-").toLowerCase();
    id = id.replace(/--+/gi, "-");
    return id;
}

const Utils = {
    getId
};
  
export default Utils