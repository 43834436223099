/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import React from "react";
import PostsProvider from "./PostsProvider";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { Button, Image } from "react-bootstrap";
import ReactPlayer from "react-player";
import gfm from "remark-gfm";
import Utils from "./Utils";

class PostsPage extends React.Component {

    MAX_LATEST = 20;
    MIN_DESCRIPTION = 150;

    provider = null;
    lang = "en";

    constructor(props){
        super(props);
        if(this.props.lang) this.lang = this.props.lang;

        this.provider = new PostsProvider(this.lang);

        this.state = {
            latestPosts : null,
            olderPosts : null,
            postInfo : null
        }
    }

    componentDidMount = async() => {

        if(!this.provider) return;

        if(!this.props.postId) return;

        await this.provider.loadPosts();
        if(!this.provider.posts) return;
        try {
            if(this.props.postId === "home"){
                await this.loadPostsHome();
            }
            else{
                await this.loadPostItem(this.props.postId);
            }
        } 
        catch (error) {
            console.log(error);
        }
        
    }

    loadPostItem = async(postId) => {
        let postIndex = this.provider.posts;
        let msg = window.mciTranslations;
        let postInfo = {};
        postInfo.title = msg.NotFound[this.lang];

        //find the id
        for(let postEntry of postIndex){
            let curId = Utils.getId(postEntry.title);
            let lcPostId = postId.toLowerCase();
            if(curId.indexOf(lcPostId) === 0){ //item found
                //get the text
                let response = await axios.get(`/content/posts/${this.lang}/${postEntry.file}`);
                if(response.status === 200){
                    postInfo = postEntry;
                    postInfo.text = response.data;
                    postInfo.date = postEntry.date.toLocaleDateString();
                }
                break;
            }
        }
        this.setState({postInfo:postInfo});
    }

    loadPostsHome = async() => {
        let latestPostsIndex = this.provider.posts.slice(0,this.MAX_LATEST);
        let latestPosts = [];
        for(let postEntry of latestPostsIndex){
            let cardInfo = postEntry;
            //get the text
            let response = await axios.get(`/content/posts/${this.lang}/${postEntry.file}`);
            if(response.status === 200){
                let text = response.data;
                text = text.replace(/^#.*$/,"");
                if(text.length > this.MIN_DESCRIPTION){
                    let firstNL = text.indexOf("\n", this.MIN_DESCRIPTION);
                    if(firstNL > -1){
                        text = text.substr(0, firstNL) + "..";
                    }
                }
              
                text = text.replace(/[`*]/g,"");

                cardInfo.description = text;
                cardInfo.id = Utils.getId(postEntry.title);

                latestPosts.push(cardInfo);
            }
        }

        let olderPosts = [];
        if(this.provider.posts.length > this.MAX_LATEST){
            olderPosts = this.provider.posts.slice(this.MAX_LATEST);
        }

        this.setState({latestPosts:latestPosts, olderPosts:olderPosts});

    }

   

    render(){
        if(!window.mciTranslations || !this.provider.posts) return <></>;
        let msg = window.mciTranslations;
        let markup = <></>;
        let latestPostsMarkup = <></>;
        let olderPostsMarkup = <></>;
        if(this.props.postId === "home"){
            if(this.state.latestPosts){
                latestPostsMarkup = this.state.latestPosts.map((entry,index)=>{
                return <span key={`latest-post-${index}`}>
                    <a href={`/${entry.id}`}><h2>{entry.title}</h2></a> 
                    <blockquote className="blockquote">{entry.date.toLocaleDateString()}</blockquote>
                    {entry.preview && (<Image className="w-100" fluid src={`/content/posts/${this.lang}/${entry.preview}`}></Image>)}
                    <p>{entry.description}</p>
                    <Button variant="outline-primary pull-right" href={`/${entry.id}`}>{msg.ReadMore[this.lang]}</Button>
                    {index < this.state.latestPosts.length-1 && (<hr></hr>)}
                </span>
                });
            }

            if(this.state.olderPosts && this.state.olderPosts.length > 0){
                olderPostsMarkup = this.state.olderPosts.map((entry,index)=>{
                    let postId = Utils.getId(entry.title);

                    return <span key={`older-post-${index}`}>
                        <li>
                            <a href={`/${this.lang}/posts/${postId}`}>
                                {entry.date.toLocaleDateString()} - {entry.title}
                            </a>
                        </li>
                    </span>
                });
                olderPostsMarkup = <>
                    <hr></hr>
                    <h5>{msg.OlderPosts[this.lang]}</h5>
                    <ul>{olderPostsMarkup}</ul>
                </>
            }


            markup = <>
                {latestPostsMarkup}
                {olderPostsMarkup}
            </>
        }
        else{
            let postInfo = this.state.postInfo;
            if(!postInfo) return <></>;
            document.title = `${postInfo.title} | My Guitar Corner`
            markup = <>
                <h2>{postInfo.title}</h2>
                <blockquote>{postInfo.date}</blockquote>
                {postInfo.preview && (<Image className="w-100 p-4" fluid src={`/content/posts/${this.lang}/${postInfo.preview}`}></Image>)}
                <ReactMarkdown plugins={[gfm]} source = {postInfo.text} allowDangerousHtml={true}></ReactMarkdown>
                
                {postInfo.video && (
                    <>
                        <h2>{msg.Interpretation[this.lang]}</h2>
                        <ReactPlayer url={postInfo.video} width="100%"
                        volume={1} pip={true} controls playing={false}></ReactPlayer>
                    </>
                )}


                {postInfo.download && (
                    <>
                        <h3>Score</h3>
                        <p>Click the button below to download the score.</p>
                        <Button variant="outline-primary" href={`/content/posts/${this.lang}/${postInfo.download}`}>{msg.Download[this.lang]}</Button>
                    </>
                )}

            </>
        }

        return (
            <div className="mb-4">{markup}</div>
        );
    }

}
  
export default PostsPage;
  