/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import './App.css';
import HomePage from './HomePage';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact={true} component={HomePage}></Route>
        <Route path="/:lang/:pageType/:pageId" children={(args) => { return <HomePage routerArgs={args} />}} />
        <Route path="/:pageId" children={(args) => { return <HomePage routerArgs={args} />}} />
      </Switch>
      </Router>
  );
}

export default App;
