/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import React from "react";
import { Container, Button, Row, Col} from 'react-bootstrap';
import PostsPage from "./PostsPage";
import PostsSidebar from "./PostsSidebar";
import YamlLoader from "./YamlLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

class HomePage extends React.Component {

  constructor(props) {
    super();
    this.state = {
      messagesLoaded : false
    }
  }

  componentDidMount = async() => {
    let loader = new YamlLoader();
    let messagesYaml = await loader.load("/content/translations.yml");
    if(messagesYaml){ 
      //save the messages in the window for other components
      window.mciTranslations = messagesYaml;
      this.setState({messagesLoaded:true}); 
    }
  }

  render(){
    if(!this.state.messagesLoaded) return <></>;

    let lang = "en", pageType = "pages", pageId = "home";
    let msg = window.mciTranslations;


    if(this.props.routerArgs && this.props.routerArgs.match){
      let langArg = this.props.routerArgs.match.params.lang;
      if(langArg) lang = langArg;
      pageType = this.props.routerArgs.match.params.pageType;
      pageId = this.props.routerArgs.match.params.pageId;
    }

    if(pageType && pageId){
      let title = msg.SiteName[lang];
      if(pageType === "posts"){
        title = `${msg.Posts[lang]} | ${title}`;
      }
      else{
        switch(pageId){
          case "about": title = `${msg.About[lang]} | ${title}`; break;
          case "contact": title = `${msg.Contact[lang]} | ${title}`; break;
          default:
        }
      }

      document.title = title;
    }

    return (
        <>
        <Container fluid className="border px-0">
          <Container>
            <h1 className="App-header">{msg.SiteName[lang]}</h1>
          </Container>

          <div className="App-menu">
            <Container>
                <Button variant="link" href="/"> <FontAwesomeIcon icon={faHome}></FontAwesomeIcon> </Button>
            </Container>
          </div>

          <Container>
            <Row className="m-0">
              <Col sm={8} className="p-0 h-100">
                <PostsPage lang={lang} postId={pageId} ></PostsPage>
              </Col>
              <Col sm={4} className="px-5 h-100">
                <PostsSidebar lang={lang}></PostsSidebar>
              </Col>
            </Row>
          </Container>

          <footer className="p-3 text-center App-footer">
              <p>
                  {msg.SiteName[lang]}
              </p>
          </footer>
          
        </Container>
        
      </>

        
      );
  }
}

export default HomePage;
