/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import YamlLoader from "./YamlLoader";

class PostsProvider {

    posts = [];
    lang = "en";
    
    constructor(langArg){
        this.lang = langArg;
    }

    loadPosts = async() => {
        let yamlLoader = new YamlLoader();
        this.posts = await yamlLoader.load(`/content/posts/${this.lang}/posts-index.yml`);
    }



}
  
export default PostsProvider;
  